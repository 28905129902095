import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
};

const userModule = {
  state: () => ({
    company: null,
    role: null,
    companies: [],
    featureFlags: [],
    isAdmin: false,
    email: null,
    isSelfServe: false,
    chargeBeeSubscription: null,
    isUmsEnabled: false,
    featurePackage: null
  }),
  mutations: {
    updateUserCompany(state, company) {
      state.company = company;
    },
    updateUserRole(state, role) {
      state.role = role;
    },
    updateUserCompanies(state, companies) {
      state.companies = companies;
    },
    updateFeatureFlags(state, featureFlags) {
      state.featureFlags = featureFlags;
    },
    updateUserEmail(state, email) {
      state.email = email;
    },
    updateIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    updateIsSelfServe(state, isSelfServe) {
      state.isSelfServe = isSelfServe;
    },
    updateChargeBeeSubscription(state, chargeBeeSubscription) {
      state.chargeBeeSubscription = chargeBeeSubscription;
    },
    updateIsUmsEnabled(state, isUmsEnabled) {
      state.isUmsEnabled = isUmsEnabled;
    },
    updateFeaturePackage(state, featurePackage) {
      state.featurePackage = featurePackage;
    }
  },
  getters: {
    isSelfServe(state) {
      return state.isSelfServe;
    },
    isOwner(state) {
      return state.role === 'Owner';
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    isDatasnipperOwnUser(state) {
      if (state.email === null) {
        return null;
      }
      return state.email.indexOf('@datasnipper.com') !== -1;
    },
    hasSSO(state) {
      return state.featureFlags.includes('SSO');
    },
    isFeatureFlagEnabled: (state) => (ff) => state.featureFlags.includes(ff),
    isChargeBeeSubscriptionCancelled: (state) =>
      state.chargeBeeSubscription?.status === 'Cancelled',
    isUmsEnabled(state) {
      return state.isUmsEnabled;
    },
    isProfessional(state) {
      return state.featurePackage === 'Professional package';
    },
    isEnterprise(state) {
      return state.featurePackage === 'Enterprise package';
    },
    hasCompanyTemplates(state) {
      return state.featureFlags.includes('Company Templates');
    }
  }
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },
  set(state, [variable, value]) {
    state[variable] = value;
  }
};

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    user: userModule
  }
});
